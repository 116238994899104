import { BindingEngine, Disposable, autoinject, bindable } from 'aurelia-framework';
import { ValidateResult, ValidationController } from 'aurelia-validation';

@autoinject
export class ValidationControllerErrors {
  /**
   * Errors that is available from the validation controller
   */
  @bindable
  controller: ValidationController; //Error[] = [];

  /**
   * The property name to display errors for.
   * Name given in the ValidationRules.ensure decorator
   */
  @bindable
  propertyName: string = '';

  /**
   * The property name to display errors for.
   * Name given in the ValidationRules.ensure decorator
   */
  @bindable
  hideMessages = false;

  /**
   * The errors that should be displayed for the property
   * This is a subset of the errors array
   */
  protected displayErrors: ValidateResult[] = [];

  private subscriptions: Disposable[] = [];

  private bindingEngine: BindingEngine;
  constructor(
    bindingEngine: BindingEngine,
    private element: Element
  ) {
    this.bindingEngine = bindingEngine;
  }

  attached() {
    // Subscribe to the errors array from the validation controller
    const subscription = this.bindingEngine
      .collectionObserver(this.controller.errors)
      .subscribe(this.errorsChanged.bind(this));
    this.subscriptions.push(subscription);
  }

  /**
   * Called when the errors array changes from the validation controller.
   * Filters the errors to only include errors for the property name
   */
  protected errorsChanged() {
    this.displayErrors = this.controller.errors.filter(
      (e) => e.valid === false && e.propertyName === this.propertyName
    );
    if (this.displayErrors.length) {
      const wrapper = this.element.querySelector('.form-element');
      wrapper.classList.add('form-element--has-error');
    } else {
      const wrapper = this.element.querySelector('.form-element');
      wrapper.classList.remove('form-element--has-error');
    }
  }

  detached() {
    // Unsubscribe all subscriptions to avoid memory leaks
    this.subscriptions.forEach((s) => s.dispose());
  }
}
